import { createRouter, createWebHashHistory } from 'vue-router'
// import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/competitionList',
    name: 'competitionList',
    component: () => import('../views/competitionList.vue')
  },
  {
    path: '/bifenOdds',
    name: 'bifenOdds',
    component: () => import('../views/bifenOdds.vue')
  },
  {
    path: '/goalModel',
    name: 'goalModel',
    component: () => import('../views/goalModel.vue')
  },
  {
    path: '/bifenList',
    name: 'okoooList',
    component: () => import('../views/okoooList.vue')
  },
  {
    path: '/bifenOdd',
    name: 'okoooOdd',
    component: () => import('../views/okoooOdd.vue')
  },
  {
    path: '/bifenModelDI',
    name: 'bifenModelDI',
    component: () => import('../views/bifenModelDI.vue')
  },
  {
    path: '/mengshibifenList',
    name: 'mengshiokoooList ',
    component: () => import('../views/mengshiokoooList.vue')
  },
  {
    path: '/mengshiziyong',
    name: 'mengshiziyong',
    component: () => import('../views/mengshiziyong.vue')
  },
  {
    path: '/bifenluoji',
    name: 'bifenluoji',
    component: () => import('../views/bifenluoji.vue')
  },
  {
    path: '/zonghetuli',
    name: 'zonghetuli',
    component: () => import('../views/zonghetuli.vue')
  },
  {
    path: '/zonghetuli/admin',
    name: 'zonghetuliadmin',
    component: () => import('../views/zonghetuliadmin.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/mengshiadmin',
    name: 'mengshiadmin',
    component: () => import('../views/mengshiAdmin.vue')
  },
  {
    path: '/mengshiregister',
    name: 'mengshiregister',
    component: () => import('../views/mengshiRegister.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
