<template>
  <div>
    <el-menu router :default-active="activeIndex2" class="el-menu-demo" mode="horizontal" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" @select="handleSelect">
      <el-menu-item index="/">
        <template #title>
          <img style="margin-right:10px;width:40px;height:40px;border-radius:20px;" src="./assets/logo.jpeg">
          <span style="font-size:16px;color:yellow;">猛士之家</span>
        </template>
      </el-menu-item>
      <el-menu-item index="/bifenList">猛士比分指数</el-menu-item>
      <el-menu-item index="/goalModel">猛士进球模型软件</el-menu-item>
      <!--el-menu-item index="/mengshiziyong">猛士自用</el-menu-item-->
      <!--el-menu-item index="/bifenModelDI">比分模型走DI（精）</el-menu-item-->
      <!--el-menu-item index="/zonghetuli">综合图例</el-menu-item-->
      <el-menu-item index="/bifenluoji">比分逻辑模块</el-menu-item>
      <el-sub-menu v-if="user">
        <template #title>欢迎您，{{user}}</template>
        <el-menu-item index="quit" @click="quit">退出</el-menu-item>
      </el-sub-menu>
      <el-menu-item v-else index="/login">登录</el-menu-item>
    </el-menu>
    <!--div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div-->
    <router-view></router-view>
    <div style="position: fixed; bottom: 10px; text-align: center; width: 100%;">备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index">冀ICP备2021025981号</a></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      user: this.$store.state.user
    }
  },
  computed: { // computed是计算属性，state里面的url改变之后，就会进行一次计算，并返回计算值到对应的参数中
    users () {
      console.log(this.$store.state.user)
      return this.$store.state.user
    }
  },
  watch: {
    users () {
      console.log(this.$store.getters.getUser)
      this.user = this.$store.getters.getUser
    }
  },
  mounted () {
    this.addWaterMarker('猛士之家进球数图例官网：mengshizhijia.com\n官方公众号：猛士之家')
  },
  methods: {
    addWaterMarker (str) {
      var can = document.createElement('canvas')
      var body = document.getElementById('app')
      body.appendChild(can)
      can.width = 10
      can.height = 150
      can.style.display = 'none'
      var cans = can.getContext('2d')
      cans.rotate(-20 * Math.PI / 180)
      cans.font = '16px Microsoft JhengHei'
      cans.fillStyle = 'rgba(17, 17, 17, 0.30)'
      cans.textAlign = 'left'
      cans.textBaseline = 'Middle'
      cans.fillText(str, can.width / 6, can.height / 2)
      body.style.backgroundImage = 'url(' + can.toDataURL('image/png') + ')'
    },
    quit () {
      console.log('quit')
    }
  }
}
</script>
<style>
html, body{
  margin: 0;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
